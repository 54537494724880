import React from 'react';

function Contact() {
  return (
    <>
      <div>
        <div className="HeroImgHolder"><img src="img/backgroundinstumentImage.jpg"></img></div>
        <div>
          <div className='w-100 d-flex align-items-center justify-content-center mt-5 mb-5'>
            <span className='products-manufactured-text archivo-normal-black-pearl-3-5rem-700'>
              Contact Us
            </span>
          </div>
          <div className="row mx-auto">
            <div className="col-sm-12 col-xl-5">
              <div className="contact-div">
                <span className="archivo-normal-black-pearl-3-5rem-700">For Customers</span>
                <span className="archivo-normal-black-pearl-2rem-700">Get quick answers to all your queries</span>
                <a href="mailto:">Send a Mail</a>
              </div>
              <div className="contact-div">
                <span className="archivo-normal-black-pearl-3-5rem-700">For Businesses</span>
                <span className="archivo-normal-black-pearl-2rem-700">Get custom quotes for bulk demands</span>
                <a href="mailto:">Send a Mail</a>
              </div>
            </div>
            <div className="col-sm-12 col-xl-6 offset-xl-1">
              <div className="map-container">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.812470797082!2d72.81698132850723!3d19.377271005633208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa07a46cb60893499!2zMTnCsDIyJzM4LjIiTiA3MsKwNDknMTcuMyJF!5e0!3m2!1sen!2sin!4v1665329943076!5m2!1sen!2sin" width="100%" height="100%"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact;