import React from 'react'

function Certifications() {
  return (
    <div className='row my-5'>
        <div className='col-xl-4 col-md-6 col-sm-12'><div className='cert-wrapper'><img src='img/iso.png' /><span className="archivo-bold-black-pearl-2rem">ISO 13485 : 2016</span></div></div>
        <div className='col-xl-4 col-md-6 col-sm-12'><div className='cert-wrapper'><img src='img/ce_mark.png' /><span className="archivo-bold-black-pearl-2rem">CE Mark</span></div></div>
        <div className='col-xl-4 col-md-6 col-sm-12'><div className='cert-wrapper'><img src='img/GMP.png' /><span className="archivo-bold-black-pearl-2rem">GMP</span></div></div>
    </div>
)
}

export default Certifications;