import React from "react";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './globals.sass';
import './styleguide.sass';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import {createStore, applyMiddleware} from 'redux'
import  {Provider} from 'react-redux'
import rootReducer from "./reducers/rootReducer";
import thunk from "redux-thunk";
import { HelmetProvider } from "react-helmet-async";

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.hydrate(
	<BrowserRouter>
		<React.StrictMode>
			<Provider store={store}>
				<HelmetProvider>
					<App />
				</HelmetProvider>
			</Provider>
		</React.StrictMode>
	</BrowserRouter>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
