import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import {carouselImageData} from '../../static/carouselImageData'
function ImageSlider() {
  return (
		<div className='image-carousel'>
			<Carousel>
				{carouselImageData.imgDataArray.map((data, index)=>{
					return (
						<Carousel.Item interval={3000} kay={index}>
							<img className='d-block w-90 carousel-img' src={data.image} alt='First slide' />
							{/*<Carousel.Caption>
								<h3>First slide label</h3>
								<p>
									Nulla vitae elit libero, a pharetra augue mollis interdum.
								</p>
							</Carousel.Caption>*/}
					</Carousel.Item>
					);
				})}
				
				{/* <Carousel.Item interval={1000}>
					<img
						className='d-block w-100'
						src='img/sabchalo47ymy.jpg'
						alt='Second slide'
					/>
					<Carousel.Caption>
						<h3>Second slide label</h3>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<img
						className='d-block w-100'
						src='img/Lemon Tree Premier Jaipur HeroImage.jpeg'
						alt='Third slide'
					/>
					<Carousel.Caption>
						<h3>Third slide label</h3>
						<p>
							Praesent commodo cursus magna, vel scelerisque nisl consectetur.
						</p>
					</Carousel.Caption>
				</Carousel.Item> */}
			</Carousel>
		</div>
	);
}

export default ImageSlider