export const clinicalData = {
	image:
		'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/carousel_images/carouselOne.svg',
	header: {
		productName: 'PRODUCT NAME',
		type: 'TYPE',
		method: 'METHOD',
		codeNo: 'CODE NO.',
		packSize: 'PACK SIZE',
		pdfInstructions: 'PDF INSTRUCTIONS',
	},
	dataArray: [
		{
			productName: 'ADA',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'ENZYMATIC KINETIC',
			codeNo: 'MB3201 MB3202',
			packSize: '1 X 15 ML 2 X 15 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/ADA.pdf',
		},
		{
			productName: 'ALBUMIN',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'BCG',
			codeNo: 'MB0101 MB0104',
			packSize: '2 X 100 ML 1 X 100 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/ALBUMIN.pdf',
		},
		{
			productName: 'ALKALINE PHOSPHATASE',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'PNPP/AMP KINETIC',
			codeNo: 'MB0201 MB0205',
			packSize: '25 X 1 ML 50 X 1 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/ALP_Mono.pdf',
		},
		{
			productName: 'ALKALINE PHOSPHATASE',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'PNPP/AMP KINETIC',
			codeNo: 'MB0211 MB0222',
			packSize: '2 X 25 ML 4 X 25 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/ALP_two_reg..pdf',
		},
		{
			productName: 'AMMONIA',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'UV KINETIC',
			codeNo: 'MB3401 MB3402',
			packSize: '1 X 10 ML 1 X 25 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/AMMONIA.pdf',
		},
		{
			productName: 'AMYLASE',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'GAL-G2-CNP',
			codeNo: 'MB0301 MB0302 MB0303',
			packSize: '1 X 10 ML 1 X 25 ML 10 X 1 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/AMYLASE.pdf',
		},
		{
			productName: 'ASO LATEX',
			type: 'LIQ. STABLE REAGENTS',
			method: 'LATEX METHOD',
			codeNo: 'MB4001 MB4002 MB4003',
			packSize: '25 TEST 50 TEST 100 TEST',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/ASO_Latex.pdf',
		},
		{
			productName: 'ASO TURBILATEX',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'LATEX TURNIDIMETRY',
			codeNo: 'MB3601',
			packSize: '1 X 50 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/ASO_TURBI.pdf',
		},
		{
			productName: 'BILIRUBIN T & D',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'BREGH & MULLER',
			codeNo: 'MB0411 MB0412 MB0413',
			packSize: '2 X 25 ML 4 X 25 ML 2 X 100 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/Bilirubin_Single_Reagent.pdf',
		},
		{
			productName: 'BILIRUBIN T & D',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'JENDRASSIK-GROF',
			codeNo: 'MB0401',
			packSize: '2 X 100 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/Bilirubin.pdf',
		},
		{
			productName: 'CALCIUM',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'ARSENAZO',
			codeNo: 'MB0501 MB0502 MB0503 MB0504',
			packSize: '25 X 1 ML 50 X 1 ML 4 X 25 ML 8 X 25 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/CALCIUM_ARCENAZO.pdf',
		},
		{
			productName: 'CALCIUM OCPC',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'OCPC',
			codeNo: 'MB0511 MB0512',
			packSize: '4 X 25 ML 8 X 25 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/CALCIUM_OCPC.pdf',
		},
		{
			productName: 'CHLORIDE',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'MTC',
			codeNo: 'MB0601 MB0602',
			packSize: '25 X 1 ML 50 X 1 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/Chloride.pdf',
		},
		{
			productName: 'CHOLESTROL',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'CHOD/POD',
			codeNo: 'MB0701 MB0702 MB0703',
			packSize: '2 X 25 ML 4 X 25 ML 4 X 50 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/Cholesterol.pdf',
		},
		{
			productName: 'CHOLINESTERASE',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'BUTYRYLTHIOCOLINE',
			codeNo: 'MB3101 MB3102',
			packSize: '1 X 10 ML 1 X 25 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/Cholinestrace.pdf',
		},
		{
			productName: 'CK-MB',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'IMMUNO INHIBITION',
			codeNo: 'MB0801 MB0802',
			packSize: '1 X 10 ML 1 X 25 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/CK_MB_LIQUID.pdf',
		},
		{
			productName: 'CK-NAC',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'NAC-ACTIVATED',
			codeNo: 'MB0901 MB0902',
			packSize: '1 X 10 ML 1 X 25 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/CK_Nac_LIQUID.pdf',
		},
		{
			productName: 'CREATININE',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'ALK-PICRATE',
			codeNo: 'MB1051 MB1052',
			packSize: '2 X 100 ML 4 X 25 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/CREATININE_Mono.pdf',
		},
		{
			productName: 'CREATININE',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'ALK-PICRATE',
			codeNo: 'MB1002, MB1003',
			packSize: '2 X 100 ML 4 X 25 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/CREATININE.pdf',
		},
		{
			productName: 'CRP LATEX',
			type: 'LIQ. STABLE REAGENTS',
			method: 'LATEX METHOD',
			codeNo: 'MB4101 MB4102 MB4103',
			packSize: '25 TEST 50 TEST 100 TEST',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/CRP_Latex_.pdf',
		},
		{
			productName: 'CRP TURBILATEX',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'LATEX TURNIDIMETRY',
			codeNo: 'MB3701',
			packSize: '1 X 50 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/CRP_TURBI.pdf',
		},
		{
			productName: 'G6PD',
			type: 'POWDER FORM',
			method: 'QUALITATIVE SCREENING',
			codeNo: 'MB3301',
			packSize: '10 TESTS',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/G6PD.pdf',
		},
		{
			productName: 'GAMMA GT',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'IFCC KINETIC',
			codeNo: 'MB1301 MB1302 MB1303',
			packSize: '1 X 10 ML 1 X 25 ML 4 X 25 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/GGT.pdf',
		},
		{
			productName: 'GLUCOSE',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'GOD/POD',
			codeNo: 'MB1401 MB1402',
			packSize: '6 X 100 ML 2 X 500 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/Glucose.pdf',
		},
		{
			productName: 'HDL DIRECT',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'SDT',
			codeNo: 'MB1521 MB1522 MB1523 MB1524',
			packSize: '1 X 20 ML 2 X 20 ML 2 X 40 ML 4 X 40 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/HDL_DIRECT_1_REAGENT.pdf',
		},
		{
			productName: 'HDL PPT',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'PEG',
			codeNo: 'MB6101 MB6102',
			packSize: '1 X 10 ML 2 X 25 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/HDL_PPT.pdf',
		},
		{
			productName: 'LACTASE',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'LO-POD ENZYMATIC COLORIMETRIC',
			codeNo: 'MB3901 MB3902',
			packSize: '1 X 10 ML 1 X 25 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/Lactate.pdf',
		},
		{
			productName: 'LDH-P',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'IFCC KINETIC',
			codeNo: 'MB2801 MB2802',
			packSize: '1 X 10 ML 1 X 25 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/LDH-P.pdf',
		},
		{
			productName: 'LDL DIRECT',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'SDT',
			codeNo: 'MB1601 MB1602 MB1604',
			packSize: '1 X 20 ML 2 X 20 ML 2 X 40 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/LDL_DIRECT.pdf',
		},
		{
			productName: 'LIPASE',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'METHYL RESORUFIN',
			codeNo: 'MB2701 MB2702',
			packSize: '1 X 12 ML 2 X 12 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/LIPASE.pdf',
		},
		{
			productName: 'MAGNESIUM',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'XYLIDYL BLUE',
			codeNo: 'MB3001 MB3002',
			packSize: '25 X 1.1 ML 50 X 1.1 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/Magnessium.pdf',
		},
		{
			productName: 'MICROPROTEIN',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'PYROGALLOL RED',
			codeNo: 'MB3801 MB3802',
			packSize: '25 X 1 ML 50 X 1 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/',
		},
		{
			productName: 'PHOSPHORUS',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'UV METHOD',
			codeNo: 'MB2901 MB2902',
			packSize: '25 X 1.1 ML 50 X 1.1 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/Phosphorous.pdf',
		},
		{
			productName: 'POTASSIUM',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'COLORIMETRIC',
			codeNo: 'MB1901 MB1902',
			packSize: '25 X 1.1 ML 50 X 1.1 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/Potassium.pdf',
		},
		{
			productName: 'RF LATEX',
			type: 'LIQ. STABLE REAGENTS',
			method: 'LATEX METHOD',
			codeNo: 'MB4201 MB4202 MB4203',
			packSize: '25 TEST 50 TEST 100 TEST',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/RF_Latex.pdf',
		},
		{
			productName: 'RF TURBILATEX',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'LATEX TURNIDIMETRY',
			codeNo: 'MB3501',
			packSize: '1 X 50 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/RF_TURBI.pdf',
		},
		{
			productName: 'SGOT',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'IFCC KINETIC',
			codeNo: 'MB2001 MB2002 MB2003',
			packSize: '2 X 25 ML 4 X 25 ML 4 X 50 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/SGOT.pdf',
		},
		{
			productName: 'SGPT',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'IFCC KINETIC',
			codeNo: 'MB2101 MB2102 MB2103',
			packSize: '2 X 25 ML 4 X 25 ML 4 X 50 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/SGPT.pdf',
		},
		{
			productName: 'SODIUM',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'COLORIMETRIC',
			codeNo: 'MB2201 MB2202',
			packSize: '25 X 1.1 ML 50 X 1.1 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/SODIUM.pdf',
		},
		{
			productName: 'TOTAL PROTEIN',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'BIURET',
			codeNo: 'MB2301 MB2306',
			packSize: '2 X 100 ML 1 X 200 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/Total_Protein.pdf',
		},
		{
			productName: 'TRIGLYCERIDES',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'GPO/POD',
			codeNo: 'MB2401 MB2402 MB2403',
			packSize: '2 X 25 ML 4 X 25 ML 4 X 50 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/Triglycerides.pdf',
		},
		{
			productName: 'UREA BERTHLOT',
			type: 'POWDER FORM',
			method: 'BERTHELOT',
			codeNo: 'MB2511',
			packSize: '4 X 50 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/Urea_Berthelot.pdf',
		},
		{
			productName: 'UREA UV',
			type: 'LIQ. STABLE TWO REAGENTS',
			method: 'UV KINETIC',
			codeNo: 'MB2501 MB2502 MB2503',
			packSize: '2 X 25 ML 4 X 25 ML 4 X 50 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/Urea_UV.pdf',
		},
		{
			productName: 'URIC ACID',
			type: 'LIQ. STABLE 1 REAGENT',
			method: 'URICASE/POD',
			codeNo: 'DL2601 DL2602 DL2603 DL2604 DL2605',
			packSize: '50 X 1.1 ML 2 X 25 ML 4 X 25 ML 4 X 50 ML 25 X 1.1 ML',
			pdfInstructions:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/Uric_Acid.pdf',
		},
	],
};

    