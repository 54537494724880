
import React from 'react'
import data from '../Data/HomePageProductData.json'

import { useHistory } from 'react-router';
function HomeProductList() {

	const history = useHistory();

    const renderCards = () =>{
        return data.instrimunts.map((val, index) => {
					return (
						<div key={index} className='col-xl-3 col-lg-4 col-md-6 col-sm-12'>
							<div className='card-product-view cursor-pointer' onClick={()=>   history.push('/products/inst')}>
								<img src={val.imageLinks} className='image' />
								<div class='middle'>
									<div class='archivo-normal-black-pearl-3rem-700'>{val.imageName}</div>
								</div>
							</div>
						</div>
					);
				});
    }
  return (
		<>
			<div className='pt-5 w-100 d-flex align-items-center justify-content-center'>
				<span className='products-manufactured-text archivo-normal-black-pearl-3-5rem-700'>
					Clinical Instrumentation
				</span>
			</div>
			<div className='HomeProductList-div'>
				<div className='row '>{renderCards()}</div>
			</div>
		</>
	);
}

export default HomeProductList