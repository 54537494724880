import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
const Navbar = () => {

	let [open, setOpen] = useState(false);
	const [showDropDown, setShowDropDown] = useState(false)
	const history = useHistory();
	useEffect(()=>{
		setShowDropDown(false);
	},[])

	const toggleDropDownMenue = (e) =>{
		e.preventDefault()
		setShowDropDown(!showDropDown);
	}
	return (
		<div className='shadow-md w-100 fixed top-0 left-0'>
			<div className='md:flex items-center justify-between bg-white py-4 md:px-10 px-7'>
				<div
					className='font-bold text-2xl cursor-pointer flex items-center
      text-gray-800 '
					onClick={() => history.push('/')}>
					<span className='text-5xl text-indigo-600 d-flex mr-1 pt-2 logo-class'>
						<img src='logo.svg' alt='logo' />
						<span className='archivo-bold-text-logo-pearl-4rem d-flex align-items-center ml-4'>
							Manas Biomedicals
						</span>
					</span>
				</div>

				<div
					onClick={() => setOpen(!open)}
					className='text-3xl absolute right-8 top-6 cursor-pointer md:hidden'>
					<ion-icon name={open ? 'close' : 'menu'}></ion-icon>
				</div>

				<ul
					className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 pr-20 transition-all duration-500 ease-in ${
						open ? 'top-20 ' : 'top-[-490px]'
					}`}>
					<li className='md:ml-8 text-xl md:my-0 my-7'>
						<Link
							to={'/'}
							className='text-gray-800 hover:text-gray-400 duration-500 archivo-normal-black-pearl-2rem-700'>
							{'Home'}
						</Link>
					</li>

					<li className='md:ml-8 text-xl md:my-0 my-7 relative'>
						<OutsideClickHandler
							onOutsideClick={() => {
								setShowDropDown(false);
							}}>
							<span
								className='text-gray-800 hover:text-gray-400 duration-500  archivo-normal-black-pearl-2rem-700'
								onClick={(e) => toggleDropDownMenue(e)}>
								{'Product'}
							</span>
							{showDropDown && (
								<div className='dropdown-products-div absolute'>
									<div className='product-dropDown-content'>
										<Link
											to={'/products/ccr'}
											className='text-gray-800 hover:text-gray-400 duration-500 archivo-normal-black-pearl-2rem-700'>
											{'Clinical Chemistry Reagent'}
										</Link>
									</div>
									<div className='product-dropDown-content'>
										<Link
											to={'/products/inst'}
											className='text-gray-800 hover:text-gray-400 duration-500 archivo-normal-black-pearl-2rem-700'>
											{'Instrumentation'}
										</Link>
									</div>
								</div>
							)}
						</OutsideClickHandler>
					</li>
					<li className='md:ml-8 text-xl md:my-0 my-7'>
						<Link
							to={'/about'}
							className='text-gray-800 hover:text-gray-400 duration-500 archivo-normal-black-pearl-2rem-700'>
							{'About Us'}
						</Link>
					</li>
					{/* <li className='md:ml-8 text-xl md:my-0 my-7'>
						<Link
							to={'/distributors'}
							className='text-gray-800 hover:text-gray-400 duration-500 archivo-normal-black-pearl-2rem-700'>
							{'Distributors'}
						</Link>
					</li>
					<li className='md:ml-8 text-xl md:my-0 my-7'>
						<Link
							to={'/career'}
							className='text-gray-800 hover:text-gray-400 duration-500  archivo-normal-black-pearl-2rem-700'>
							{'Career'}
						</Link>
					</li> */}
					<li className='md:ml-8 text-xl md:my-0 my-7'>
						<Link
							to={'/contact'}
							className='text-gray-800 hover:text-gray-400 duration-500 archivo-normal-black-pearl-2rem-700'>
							{'Contact'}
						</Link>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Navbar;
