import React from 'react'
import FooterTopStrip from "../Common/FooterTopStrip";
import FooterForm from './Footer/FooterForm';

function Footer() {
  return (
		<div className='footer-outer-div'>
			<FooterTopStrip />
			<div className='row footer-div-wraper'>
				<div className='col-lg-6'>
					<div className='company-name-details'>
						<div className='mb-3 archivo-normal-contact-us-pearl-4rem-900'>
							Manas Biomedicals
						</div>
						<div className='archivo-bold-black-pearl-2xrem-500 text-justify-align'>
							We are engaged in manufacturing “in vitro” Diagnostic Reagents.
							Along with fully equipped manufacturing facility for Clinical
							Chemistry Reagents and Instruments. We are a leading laboratory
							reagents & Instruments manufacturer in India. Our wide dealer &
							distributor network across India serves the needs of Pathology
							Labs & Diagnostic Centres.
						</div>
					</div>
					<div className='company-address-details'>
						<div className='mb-3 archivo-normal-black-pearl-2-5rem-700'>
							Regd. Office
						</div>
						<div className='archivo-bold-black-pearl-2xrem-500 text-justify-align'>
							Opp. Sweety Park, Grnd. Floor, Bldg. 5, Flat No. 2, DCP Nagar,
							Umela, Naigaon West, Vasai-Virar, Palghar, Maharashtra - 401202,
							INDIA
						</div>
						<div className='archivo-bold-black-pearl-2xrem-500 mt-3'>
							<span className='archivo-bold-black-pearl-2xrem-700'>Mob :</span>{' '}
							+91 - 9860235801
						</div>
						<div className='mt-5 mb-3 archivo-normal-black-pearl-2-5rem-700'>
							Plant address
						</div>
						<div className='archivo-bold-black-pearl-2xrem-500 text-justify-align'>
							1560 D OPP.GOVT.POLYTECHINIC TANDA BASKHARI ROAD AT TRIMUHANI
							BAZAR DAULATPUR HAZALPATTI AMOULIYA P.O.HAZALAPUR TANDA AMBEDKAR
							NAGAR U.P. PIN 224190
						</div>
						<div className='archivo-bold-black-pearl-2xrem-500 mt-3 mb-5'>
							<span className='archivo-bold-black-pearl-2xrem-700'>Mob :</span>{' '}
							+91 - 9420155736
						</div>

						<div className='archivo-bold-black-pearl-2xrem-500 mt-3 mb-5'>
							<span className='archivo-bold-black-pearl-2xrem-700'>
								Email :
							</span>{' '}
							info@manasbiomedicals.com
						</div>
					</div>
				</div>
				<div className='col-lg-6'>
					<div className='form-wraper-div'>
						<FooterForm />
					</div>
				</div>
			</div>
			<div className='copy-right-div archivo-normal-black-pearl-1-4rem'>
				©Copyright {new Date().getFullYear()} Manas Biomedicals. All Rights
				Reserved
			</div>
		</div>
	);
}

export default Footer