import React from "react";
import { Row, Col } from "react-bootstrap";
import 'react-awesome-slider/dist/styles.css';
import { Helmet } from "react-helmet-async";
import Navbar from "../Common/Navbar";
import ImageSlider from "../Common/ImageSlider";
import HomeProductList from "../Common/HomeProductList";
import ProductManufature from "../Common/ProductManufature";

function Home() {
	
	return (
		<>
			<Helmet>
				<title>Home</title>
				<meta
					name='description'
					content='Manas Biomedicals - Manufacturer of medical equipment, laboratory equipment & pharmaceutical tablets since 2008 in Tanda, Uttar Pradesh.'></meta>
				<link rel='canonical' href='/'></link>
			</Helmet>
			<div>
				<ImageSlider />
			</div>
			<div className='productManufature-container'>
				<ProductManufature />
			</div>
			<div>
				<HomeProductList />
			</div>
		</>
	);
}

export default Home;
