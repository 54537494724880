import React from 'react';
import data from '../Data/HomePageProductData.json';
import ImageSlider from './ImageSlider';
import { useHistory } from 'react-router';
function ProductManufature() {
	const history = useHistory();

	const redirectFunct = (data) =>{
		console.log('data:', data)
		switch(data){
			case 0: history.push('/products/ccr')
			break;
			case 1:  history.push('/products/inst')
			break;
			default: console.log('wrong data')
		}
	}
	const renderProductManufacture = () => {
		return data.productManufacture.map((val, index) => {
			return (
				<div key={index} className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
					<div className='card-product-view-product cursor-pointer' onClick={()=> redirectFunct(index)}>
						<img src={val.imageLinks} className='image' />
						<div class='middle-product'></div>
						<div class='text-product'>
							<div className='aligh-product-text archivo-normal-white-pearl-3rem-700'>
								{val.imageName}
							</div>
						</div>
					</div>
				</div>
			);
		});
	};
	return (
		<>
			<div className='pt-5 w-100 d-flex align-items-center justify-content-center'>
				<span className='products-manufactured-text archivo-normal-black-pearl-3-5rem-700'>
					Products Manufactured
				</span>
			</div>
			<div className='HomeProductList-div'>
				<div className='row '>
					
					{renderProductManufacture()}
				</div>
			</div>
		</>
	);
}

export default ProductManufature;
