import React from "react";
import { Row, Col } from "react-bootstrap";
import 'react-awesome-slider/dist/styles.css';
import { Helmet } from "react-helmet-async";
import Certifications from "../Common/Certifications";

function About() {
	
	return (
		<>
			<Helmet>
				<title>About Us</title>
				<meta
					name='description'
					content='Manas Biomedicals features a comprehensive menu of ready-to-use, liquid-stable reagents with flexible reagent packaging and methods referenced to industry standards.'></meta>
				<link rel='canonical' href='/about'></link>
			</Helmet>
			<div>
				<div className='HeroImgHolder'>
					<img src='img/backgroundinstumentImage.jpg'></img>
				</div>
				<div>
					<div className='w-100 d-flex align-items-center justify-content-center mt-5 mb-5'>
						<span className='products-manufactured-text archivo-normal-black-pearl-3-5rem-700'>
							About Us
						</span>
					</div>
					<div className='row mx-auto mb-4 archivo-normal-black-pearl-2rem line-height-1-5'>
						<p className='mt-4'>
							Our technology ensure that Life is ON for everyone, everywhere, at
							every moment.
						</p>
						<p className='mt-4'>
							We are fully devoted on developing and manufacturing “in vitro”
							Diagnostic Reagents. We are leading manufacturers of Clinical
							Chemistry Reagents under quality management and automation. We
							make it possible for users to enable solutions seamlessly correct,
							analyse and act in real-time delivering enhanced safety,
							efficiency, reliability and sustainability. Our mission is to
							bring Technology and Chemistry to enable life, progress and
							sustainability for all. We pioneer Technology and transformation
							of Chemistry and processes to turn the bold ideas of our customers
							into reality. We adhere to the highest standards of governance and
							ethics.
						</p>
						<p className='mt-4'>
							Our state-of-the-art facility, highly experienced personnel,
							formulation expertise and regulatory compliance maintain the
							following certifications:
						</p>
						<Certifications />
						<p>
							Manas Biomedicals features a comprehensive menu of ready-to-use,
							liquid-stable reagents with flexible reagent packaging and methods
							referenced to industry standards. The operator may use our
							reagents with less or no waste for time dependent degradation.
							Formulations permit the maximum performance even after years from
							production. No water or buffers required to prepare working
							reagents, just open the bottles and use the reagents.
						</p>
						<h3 className='archivo-bold-black-pearl-2rem mt-5'>Branded Kits</h3>
						<p>Available through our authorized distributors.</p>
						<h3 className='archivo-bold-black-pearl-2rem mt-5'>
							Bulk/OEM products
						</h3>
						<ul className='ul-list'>
							<li>Available for private label market.</li>
							<li>Customized sizes are available on request.</li>
						</ul>
						<h3 className='archivo-bold-black-pearl-2rem mt-5'>
							Products Manufactured
						</h3>
						<ul className='ul-list'>
							<li>Clinical Chemistry Reagents/Diagnostic Reagents</li>
							<li>
								Instruments:
								<ul>
									<li>Biochemistry Analyzer</li>
									<li>Dry Block Incubator</li>
									<li>Coagulation Analyzer</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
}

export default About;
