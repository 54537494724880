export const instrumentData = {
    header: {
        product: 'PRODUCT',
        description: 'DESCRIPTION',
        pdf: 'PDF',
    },
    dataArray:[
        {
            product: '/img/inst1.png',
            description: 'The Clinical Biochemistry Analyzer is an instrument that uses the pale yellow supernatant portion (serum) of centrifuged blood sample or a urine sample, and induces reactions using reagents to measure various components such as sugar, cholesterol, protein, enzyme, etc.',
            pdf: '/pdf/1562761298.Albumin.pdf',
           
        },
        {
            product: '/img/inst1.png',
            description: 'The Clinical Biochemistry Analyzer is an instrument that uses the pale yellow supernatant portion (serum) of centrifuged blood sample or a urine sample, and induces reactions using reagents to measure various components such as sugar, cholesterol, protein, enzyme, etc.',
            pdf: '/pdf/1562761298.Albumin.pdf',
           
        }
    ]
}
