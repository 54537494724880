import React from "react";
import { clinicalData } from '../../../static/productTableDataClinical'
import { FaFilePdf } from 'react-icons/fa'
import { Helmet } from "react-helmet-async";
function CCR() {
    const renderTableContent = () => {
        return clinicalData.dataArray.map((data) => {
            return (
                <tr>
                    <td>{data.productName}</td>
                    <td>{data.type}</td>
                    <td>{data.method}</td>
                    <td>{data.codeNo}</td>
                    <td>{data.packSize}</td>
                    <td><a href={data.pdfInstructions} target="_blank"><FaFilePdf className="pdf-icon" /></a></td>
                </tr>
            )
        })
    }
    return (
			<>
				<div>
					<Helmet>
						<title>Clinical Chemistry Reagent</title>
						<meta
							name='description'
							content='Manas Biomedicals - manufacturing Clinical Diagnostic Reagents we have developed competitive range of products with the highest quality to improve the accuracy of diagnostic tests'></meta>
						<link rel='canonical' href='/products/ccr'></link>
					</Helmet>
					<div className='HeroImgHolder'>
						<img src={clinicalData.image}></img>
					</div>
					<div className='m-5'>
						<div className='w-100 d-flex align-items-center justify-content-center'>
							<span className='products-manufactured-text archivo-normal-black-pearl-3-5rem-700'>
								Clinical Chemistry Reagents
							</span>
						</div>
						<div className='row mx-auto mb-4 archivo-normal-black-pearl-2rem line-height-1-5'>
							<p className='mt-4'>
								Our products guarantee the highest Quality and consistency.
							</p>
							<p className='mt-4'>
								We are committed to understanding and exceeding our customer’s
								expectations through the continuous improvement of all the
								products. With wide experience on the research and manufacturing
								Clinical Diagnostic Reagents we have developed competitive range
								of products with the highest quality to improve the accuracy of
								diagnostic tests.
							</p>

							<div className='CustTable-holder'>
								<table className='CustTable'>
									<tr>
										<th>{clinicalData.header.productName}</th>
										<th>{clinicalData.header.type}</th>
										<th>{clinicalData.header.method}</th>
										<th>{clinicalData.header.codeNo}</th>
										<th>{clinicalData.header.packSize}</th>
										<th>{clinicalData.header.pdfInstructions}</th>
									</tr>
									{renderTableContent()}
								</table>
							</div>
						</div>
					</div>
				</div>
			</>
		);
}

export default CCR;
