export const carouselImageData = {
	imgDataArray: [
		{
			image:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/carousel_images/productpageImage.png',
		},
		{
			image:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/carousel_images/carouselTwo1920.svg',
		},
		{
			image:
				'https://manasbiomedicalpdfs.s3.ap-south-1.amazonaws.com/carousel_images/testubetesting.png',
		},
	],
};