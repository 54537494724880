import React from "react";
import {instrumentData} from '../../../static/productTableDataInstrument'
import {FaFilePdf} from 'react-icons/fa'
import { Helmet } from "react-helmet-async";
function Inst() {
	const renderTableContent = () =>{
        return instrumentData.dataArray.map((data)=>{
            return (
                <tr>
                    <td><img src={data.product}></img></td>
                    <td>{data.description}</td>
                    <td><a href={data.pdf} target='_blank'><FaFilePdf className="pdf-icon"/></a></td>
                </tr>
            )
        })
    }
	return (
		<>
			<Helmet>
				<title>Instrumentation</title>
				<meta
					name='description'
					content='Manas Biomedicals - The operator may use our reagents with less or no waste for time dependent degradation. Formulations permit the maximum performance even after years from production.'></meta>
				<link rel='canonical' href='/products/ccr'></link>
			</Helmet>
			<div className='HeroImgHolder'>
				<img src='/img/instrumentsbackground.jpg'></img>
			</div>
			<div>
				<div className='w-100 d-flex align-items-center justify-content-center'>
					<span className='products-manufactured-text archivo-normal-black-pearl-3-5rem-700'>
						Instruments
					</span>
				</div>
				<div className='row mx-auto mb-4 archivo-normal-black-pearl-2rem line-height-1-5'>
					<p className='mt-4'>
						Our products guarantee the highest Quality and consistency.
					</p>
					<p className='mt-4'>
						We are committed to understanding and exceeding our customer’s
						expectations through the continuous improvement of all the products.
						With wide experience on the research and manufacturing Clinical
						Diagnostic Reagents we have developed competitive range of products
						with the highest quality to improve the accuracy of diagnostic
						tests.
					</p>

					<table className='CustTable'>
						<tr>
							<th>{instrumentData.header.product}</th>
							<th>{instrumentData.header.description}</th>
							<th>{instrumentData.header.pdf}</th>
						</tr>
						{renderTableContent()}
					</table>
				</div>
			</div>
		</>
	);
}

export default Inst;