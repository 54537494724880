import React from "react";
// import Navbar from "./components/Navbar/Navbar";
import { Switch, Route } from "react-router-dom";
import "./App.sass";
import Footer from "./components/Common/Footer";
import Navbar from "./components/Common/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import CCR from "./components/Products/CCR/CCR";
import Inst from "./components/Products/Inst/Inst";
import Distributors from "./components/Distributors/Distributors";
import Career from "./components/Career/Career";
import Contact from "./components/Contact/Contact";
import { useHistory } from 'react-router';

const App = () => {
	const history = useHistory();
	if (window.location.pathname === '/lander'){
		history.push('/')
	}
	
		return (
			<div>
				{/*  */}
				<div style={{ position: 'relative', zIndex: '99999' }}>
					<Navbar />
				</div>
				<div style={{ position: 'relative', zIndex: '1', marginTop: '4%' }}>
					<Switch>
						<Route exact path='/' name='home'>
							<Home />
						</Route>
						<Route exact path='/about' name='about'>
							<About />
						</Route>
						<Route exact path='/products/ccr' name='ccr'>
							<CCR />
						</Route>
						<Route exact path='/products/inst' name='inst'>
							<Inst />
						</Route>
						<Route exact path='/distributors' name='distributors'>
							<Distributors />
						</Route>
						<Route exact path='/career' name='career'>
							<Career />
						</Route>
						<Route exact path='/contact' name='contact'>
							<Contact />
						</Route>
					</Switch>
				</div>
				<div>
					<Footer />
				</div>
			</div>
		);
};

export default App;
