import React from 'react';
import {GiTestTubes, GiCardboardBox} from 'react-icons/gi'
import {MdMoreTime} from 'react-icons/md'
import {BsPatchCheckFill, BsPatchCheck} from 'react-icons/bs'
function FooterTopStrip() {
	return (
		<div className='w-100 pt-4 pb-4 footer-top-strip'>
			<div className='row mx-auto ft-strip d-flex'>
				<div className='col-xl-3 col-md-6 col-sm-12'>
					<div className='img-wrapper'>
						{/* <img src='img/blood-test.png' /> */}
						<GiTestTubes className='testube-icon' />
						<span className='archivo-bold-black-pearl-1-6rem-700'>
							Liquid Stable
						</span>
					</div>
				</div>
				<div className='col-xl-3 col-md-6 col-sm-12'>
					<div className='img-wrapper'>
						{/* <img src='img/box.png' /> */}
						<GiCardboardBox className='box-icon' />
						<span className='archivo-bold-black-pearl-1-6rem-700'>
							Ready to Use
						</span>
					</div>
				</div>
				<div className='col-xl-3 col-md-6 col-sm-12'>
					<div className='img-wrapper'>
						{/* <img src='img/blood-test.png' /> */}
						<MdMoreTime className='clock-icon' />
						<span className='archivo-bold-black-pearl-1-6rem-700'>
							Longer Shelf life
						</span>
					</div>
				</div>
				<div className='col-xl-3 col-md-6 col-sm-12'>
					<div className='img-wrapper'>
						{/* <img src='img/quality-control.png' /> */}
						<BsPatchCheck className='high-quality-icon' />
						<span className='archivo-bold-black-pearl-1-6rem-700'>
							Highest Quality
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FooterTopStrip;
